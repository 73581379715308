import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Meta from "./global/meta";
import { TileShow } from "./global/tile";
import Loader from "./global/loader";
import URI from "urijs";
import { useLocation } from "react-router-dom";
import { GET_SHOWS_AND_FILTERS } from "../@services/graphql";

const Shows = props => {
  const [page, setPage] = useState(undefined);
  const [skip, setSkip] = useState(0);
  const location = useLocation();

  const currentUri = new URI(location.pathname + location.search);
  let styles = currentUri.search(true).styles || [];
  let studios = currentUri.search(true).studios || [];
  if (styles && !Array.isArray(styles)) styles = [styles];
  if (studios && !Array.isArray(studios)) studios = [studios];
  const perPage = 40;

  const [get, { loading, data }] = useLazyQuery(GET_SHOWS_AND_FILTERS, {
    errorPolicy: 'all',
    variables: {
      limit: perPage,
      skip,
      styles: styles.length ? styles : undefined,
      studios: studios.length ? studios : undefined,
      sort: "title:asc"
    }
  });

  useEffect(() => {
    if (get) get();
  }, [location.search, get]);

  useEffect(() => {
    if (data) setPage(p => ({ ...data, shows: [...(p && p.shows ? p.shows : []), ...data.showsByStyles] }));
  }, [data]);

  const handleLoadMore = () => {
    setSkip(skip + perPage);
    get();
  };

  if (page === undefined) return <Loader />;

  const handleStyleClick = style => {
    setSkip(0);
    setPage({ ...page, shows: [] });
    const uriToModify = currentUri.clone();
    const index = styles.indexOf(style);
    if (index > -1) {
      styles.splice(index, 1);
      uriToModify.setSearch({ styles });
    } else {
      uriToModify.setSearch({ styles: [...styles, style] });
    }
    props.history.push({ pathname: uriToModify.pathname(), search: uriToModify.search() });
  };

  const handleStudioClick = studio => {
    setSkip(0);
    setPage({ ...page, shows: [] });
    const uriToModify = currentUri.clone();
    const index = studios.indexOf(studio);
    if (index > -1) {
      studios.splice(index, 1);
      uriToModify.setSearch({ studios });
    } else {
      uriToModify.setSearch({ studios: [...studios, studio] });
    }
    props.history.push({ pathname: uriToModify.pathname(), search: uriToModify.search() });
  };

  return (
    <div id="shows">
      <Meta description="" title="Shows" />
      <section className="filters">
        <ul className="studios">
          {page.studios.map(s => (
            <li key={s.city} className="studio">
              <button
                onClick={e => handleStudioClick(s.city)}
                className={studios.indexOf(s.city) > -1 ? "active" : ""}
                type="button">
                {s.city}
              </button>
            </li>
          ))}
        </ul>
        <ul className="styles">
          {[...page.styles]
            .sort((a, b) => {
              return a.name < b.name ? -1 : +1;
            })
            .map(s => (
              <li key={s.name} className="style">
                <button
                  onClick={e => handleStyleClick(s.name)}
                  className={styles.indexOf(s.name) > -1 ? "active" : ""}
                  type="button">
                  {s.name}
                </button>
              </li>
            ))}
        </ul>
      </section>
      <section className="entries">
        <div className="mosaique">
          {page.shows.map(e => (
            <TileShow key={e.slug} entry={e} />
          ))}
        </div>
      </section>
      <div className="loadMore">
        {loading ? (
          <Loader />
        ) : (
          <>
            {data && data.showsByStyles.length === perPage ? (
              <button type="button" onClick={handleLoadMore}>
                Load More
              </button>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

export default Shows;
