import React, { useCallback, useEffect, useState } from "react";
import Meta from "./global/meta";
import Loader from "./global/loader";
import { GET_SEARCH } from "../@services/graphql";
import { TileEpisode, TileShow } from "./global/tile";
import { useQuery } from "@apollo/client";
import SearchBar from "./global/searchbar";
import { useHistory, useLocation } from "react-router-dom";
import URI from "urijs";

const initialState = {
  limit: 40,
  start: 0,
}

const Search = props => {
  const location = useLocation();
  const history = useHistory();

  // Get `q` query parameter
  const { q } = new URI(location.search).search(true);
  
  const [state, setState] = useState({ ...initialState, q: q || "" });
  const { data, loading } = useQuery(GET_SEARCH, {
    errorPolicy: 'all',
    skip: !state.q.length,
    variables: state,
  })

  // Replace `q` query parameter
  useEffect(() => {
    const search = state.q.length? `?q=${state.q}` : null;
    history.replace({
      pathname: location.pathname,
      search: search,
    });
  }, [state.q, history, location.pathname])

  const search = useCallback((query) => {
    setState({ ...initialState, q: query })
  }, [])

  const foundShows = data && data.shows && data.shows.length
  const foundEpisodes = data && data.episodes && data.episodes.length

  const shows = () => {
    if (!foundShows) return;
    return (
      <section>
        <h1>Shows</h1>
        <div className="mosaique">
          {data.shows.map(e => (
            <TileShow key={e.slug} entry={e} />
          ))}
        </div>
      </section>
    )
  }

  const episodes = () => {
    if (!foundEpisodes) return;
    return (
      <section>
        <h1>Archives</h1>
        <div className="mosaique">
          {data.episodes.map(e => (
            <TileEpisode key={e.slug} entry={e} />
          ))}
        </div>
      </section>
    )
  }

  const results = () => {
    if (!state.q.length) return;
    if (!foundShows && !foundEpisodes) {
      return (
        <section>
          <h2>Sorry, no results found.</h2>
        </section>
      )
    }
    return (
      <div>
        {shows()}
        {episodes()}
      </div>
    )
  }

  return (
    <div id="search">
      <Meta description="Search through our archives" title="Search" />
      <SearchBar query={state.q} className="header" onChange={search}/>
      {loading? <Loader/> : results() }
    </div>
  )
}

export default Search;