import React, { useState } from "react";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Events = (props) => {
  return (
    <div id="festival">
      <section className="entries">
          <div className="mosaique">
            {props.events.map(e => (
              <TileEvent key={e.artists} event={e} onSelect={ () => props.onSelect(e) } />
            ))}
          </div>
        </section>
    </div>
  )
};

export const TileEvent = ({
  event: {
    artists,
    venue,
    startAt,
    type,
    poster: {
      url,
      caption,
      alternativeText,
    }
  },
  onSelect
}) => {
  const [isHover, setIsHover] = useState(false);
  const image = url || caption || alternativeText || process.env.REACT_APP_DEFAULT_IMAGE;

  return (
    <div
      className={`tile ${isHover ? "hover" : ""}`}
      onMouseEnter={e => setIsHover(true)}
      onMouseLeave={e => setIsHover(false)}
      onClick={onSelect}>
      <div className="tileImage">
        {image ?
          <LazyLoadImage alt={artists} src={image} /> :
          <p>NO IMAGE</p>
        }
      </div>
      <div className={`tileInfos`}>
        <p className="date">{venue}</p>
        <p className="title">{artists}</p>
          <p className="artists">{type}</p>
        <div className="nextBroadcast">
          <p>On {moment(startAt).format("MMM Do")}</p>
          <p>{moment(startAt).format("HA")}</p>
        </div>
      </div>
    </div>
  );
};

export default Events;