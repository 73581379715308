import React from "react";
import moment from "moment";

export const Event = ({
  event: {
    artists,
    venue,
    startAt,
    description,
    type,
    tickets,
    poster: {
      url,
      caption,
      alternativeText,
    }
  }
}) => {
  return (
  <div className="event">
    <div className="content">
      <div className="left">
        <img alt={alternativeText || caption} src={url} />
      </div>
      <div className="right">
        <div className="details">
          <div className="date">
            <p>
              {
                moment(startAt).format("ddd DD/MM/YYYY")
              } • {
                moment(startAt).format("hA")
              }
            </p>
          </div>
          <h1>{artists}</h1>
          <h2>Live au {venue}</h2>
        </div>
        <div className="description">
          <p>{description}</p>
          {/* <a className="tag" href={tickets} target="_blank" rel="noreferrer">Billetterie</a> */}
        </div>
      </div>
    </div>
  </div>
  );
};

export default Event;