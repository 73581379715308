import React from "react";
import {
  FaSoundcloud,
  FaFacebookF,
  FaInstagram,
  FaMusic,
  FaTwitter,
  FaBandcamp,
  FaMixcloud,
  FaSpotify,
  FaExternalLinkAlt,
  FaLink
} from "react-icons/fa";
import { SiDiscogs } from "react-icons/si";

const icons = [
  { type: "Soundcloud", icon: FaSoundcloud },
  { type: "Facebook", icon: FaFacebookF },
  { type: "Instagram", icon: FaInstagram },
  { type: "Twitter", icon: FaTwitter },
  { type: "Discogs", icon: SiDiscogs },
  { type: "Bandcamp", icon: FaBandcamp },
  { type: "Mixcloud", icon: FaMixcloud },
  { type: "Spotify", icon: FaSpotify },
  { type: "AppleMusic", icon: FaMusic },
  { type: "Website", icon: FaExternalLinkAlt },
  { type: "Other", icon: FaLink }
];

const Links = ({ links }) => {
  return (
    <div className="links">
      {links
        .filter(l => icons.find(i => i.type === l.type))
        .map(l => (
          <div key={l.url} className="link">
            <a target="_blank" rel="noopener noreferrer" href={l.url}>
              {React.createElement(icons.find(i => i.type === l.type).icon, {})}
            </a>
          </div>
        ))}
    </div>
  );
};

export default Links;
