import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { FaFacebookF, FaInstagram, FaMixcloud } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";
import { BsList } from "react-icons/bs";
import { FaTimes } from "react-icons/fa";

const Navbar = props => {
  const location = useLocation();
  const isMobile = useMediaQuery({ maxWidth: 1024 });
  if (isMobile) return <MobileNavbar />;

  return (
    <nav id="navbar">
      <div className="left">
        <ul>
          <li className={`${location.pathname.includes("/shows/residents") ? "active" : ""}`}>
            <Link to="/shows/residents">Shows</Link>
          </li>
          <li className={`${location.pathname.includes("/discover") ? "active" : ""}`}>
            <Link to="/discover">Discover</Link>
          </li>
          <li className={`${location.pathname.includes("/schedule") ? "active" : ""}`}>
            <Link to="/schedule">Schedule</Link>
          </li>
          <li className={`${location.pathname.includes("/about") ? "active" : ""}`}>
            <Link to="/about">About</Link>
          </li>
          <li className={`${location.pathname.includes("/search") ? "active" : ""}`}>
            <Link to="/search">Search</Link>
          </li>
        </ul>
      </div>
      <div className="middle">
        <Link to="/">
          <h1>LYL RADIO</h1>
        </Link>
      </div>
      <div className="right">
        <Icons location={location}/>
      </div>
    </nav>
  );
}

const MobileNavbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <>
      <div id="mobileNavbar">
        <div className="top">
          <div className="burger">
            <button type="button" onClick={e => setIsOpen(!isOpen)}>
              {isOpen ? <FaTimes /> : <BsList />}
            </button>
          </div>
          <div className="middle">
            <Link to="/">
              <h1>LYL RADIO</h1>
            </Link>
          </div>
          <div className="search">
            <Link onClick={e => setIsOpen(false)} to="/search">
              Search
            </Link>
          </div>
        </div>
      </div>
      <MobileMenu isOpen={isOpen} />
    </>
  );
};

const MobileMenu = ({ isOpen }) => {
  return (
    <div id="mobileMenu" className={`${isOpen ? "show" : "hide"}`}>
      <div className="content">
        <ul>
          <li>
            <Link to="/shows/residents">Shows</Link>
          </li>
          <li>
            <Link to="/discover">Discover</Link>
          </li>
          <li>
            <Link to="/schedule">Schedule</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
        </ul>
        <div className="icons">
          <a
            className="donate"
            rel="noopener noreferrer"
            target="_blank"
            href="https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=C37FVDHHSZCA6">
            Donate
          </a>
          <ul>
            <li>
              <a rel="noopener noreferrer" target="_blank" href="https://fr-fr.facebook.com/lylradio">
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/lyl_radio/">
                <FaInstagram />
              </a>
            </li>
            <li>
              <a rel="noopener noreferrer" target="_blank" href="https://www.mixcloud.com/lylradio/">
                <FaMixcloud />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const Icons = ({ location }) => {
  return (
    <ul className="icons">
      <li className={`${location.pathname.includes("/article/hyperlocal-club-x-lyl-radio") ? "active" : ""}`}>
        <Link to="/article/hyperlocal-club-x-lyl-radio">✹ ✹ LYL Radio @ Hyperlocal Club ✹ ✹</Link>
      </li>
      <li>
        <a
          className="donate"
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.paypal.com/donate/?cmd=_s-xclick&hosted_button_id=C37FVDHHSZCA6">
          Donate
        </a>
      </li>
      <li>
        <a rel="noopener noreferrer" target="_blank" href="https://fr-fr.facebook.com/lylradio">
          <FaFacebookF />
        </a>
      </li>
      <li>
        <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/lyl_radio/">
          <FaInstagram />
        </a>
      </li>
      <li>
        <a rel="noopener noreferrer" target="_blank" href="https://www.mixcloud.com/lylradio/">
          <FaMixcloud />
        </a>
      </li>
    </ul>
  );
};

export default Navbar;
