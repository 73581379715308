import React, { useState, useEffect } from "react";
import { useQuery, useLazyQuery } from "@apollo/client";
import moment from "moment";
import Meta from "./global/meta";
import Four0Four from "./404";
import useSlug from "../hooks/useSlug";
import Loader from "./global/loader";
import Links from "./global/links";
import { TileEpisode } from "./global/tile";
import { GET_SHOW, GET_EPISODES_BY_SHOW } from "../@services/graphql";

const Show = props => {
  const slug = useSlug();
  const { data } = useQuery(GET_SHOW, { 
    variables: { slug: slug }
  });

  const show = data && data.show ? data.show : data;

  if (show === null) return <Four0Four />;
  else if (show === undefined) return <Loader />;

  const image =
    show.image && show.image.formats && show.image.formats.large
      ? show.image.formats.large
      : { url: process.env.REACT_APP_DEFAULT_IMAGE };

  const metaImage = (show.image && show.image.formats && show.image.formats.small) || {
    url: process.env.REACT_APP_DEFAULT_IMAGE
  };

  return (
    <div id="show">
      <Meta description={show.description} title={show.name} image={metaImage.url} />
      <div className="separator" />
      <div className="content">
        <div className="header">
          <h2>{show.recursion} show</h2>
          <h1>{show.title}</h1>
        </div>
        <img alt={image.alternativeText || image.caption} src={image.url} />
        <Links links={show.links} />
        <div className="description">
          <h2>With {show.artists}</h2>
          <p>{show.description}</p>
        </div>
        {show.nextBroadcast ? (
          <div className="nextBroadcast">
            <h2>Next broadcast: {moment(show.nextBroadcast).format("MMMM Do - HA")}</h2>
          </div>
        ) : (
          <div className="separator" />
        )}
        <Episodes showId={show && show.id} />
      </div>
    </div>
  );
};

const Episodes = ({ showId, currentEpisode }) => {
  const perPage = 48;
  const [episodes, setEpisodes] = useState([]);
  const [skip, setSkip] = useState(0);

  const [get, { loading, data }] = useLazyQuery(GET_EPISODES_BY_SHOW, {
    errorPolicy: 'all',
    variables: {
      limit: perPage,
      skip,
      show: showId, 
      not: currentEpisode
    }
  });

  useEffect(() => {
    if (data && data.episodesByShow) setEpisodes(e => e.concat(data.episodesByShow));
  }, [data]);

  useEffect(() => {
    if (get) get();
  }, [showId, get]);

  const handleLoadMore = () => {
    setSkip(skip + perPage);
    get();
  };

  if (episodes === undefined) return <Loader />;

  return (
    <div id="episodes">
      <section className="episodes">
        <div className="mosaique">
          {episodes.map(e => (
            <TileEpisode key={e.slug} entry={e} />
          ))}
        </div>
      </section>
      <div className="loadMore">
      {loading ? (
        <Loader />
      ) : (
        <>
          {data && data.episodesByShow.length === perPage? (
            <button type="button" onClick={handleLoadMore}>
              Load More
            </button>
          ) : null}
        </>
      )}
      </div>
    </div>
  );
};

export default Show;
