import React from "react";
import { useQuery } from "@apollo/client";
import Meta from "./global/meta";
import Four0Four from "./404";
import Loader from "./global/loader";
import { GET_ABOUT } from "../@services/graphql";
import { Remarkable } from "remarkable";
var md = new Remarkable({
  html: true
});

const About = () => {
  const { data } = useQuery(GET_ABOUT);

  const about = data && data.about ? data.about : data;

  if (about === null) return <Four0Four />;
  else if (about === undefined) return <Loader />;

  return (
    <div id="about">
      <Meta description={"About LYL Radio"} title="About" />
      <div className="left">
        <h1>{about.title}</h1>
        <ul id="contacts">
          {about.contact.map(c => (
            <li key={c.name} className="entry">
              <div className="contact">
                <span>{c.name}</span>
                <span>{c.title}</span>
                <a href={`mailto:${c.email}`}>{c.email}</a>
              </div>
            </li>
          ))}
        </ul>
        {about.images.map(i => (
          <img key={i.url} alt={i.alternativeText || i.caption} src={i.url} />
        ))}
      </div>
      <div className="right">
        <h2>Independent Web Radio</h2>
        <div dangerouslySetInnerHTML={{ __html: md.render(about.description) }} />
      </div>
    </div>
  );
};

export default About;
