import React from "react";

export const PlayButton = (props = {}) => {
  const { color } = props;
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="80" viewBox="0 0 82 80">
      <g fill="none" fillRule="evenodd">
        <g>
          <g fill={color || "#fff"} fillRule="nonzero">
            <path d="M0.747 25.335L14.947 16.883 21.747 12.835 0.747 0.335z" transform="translate(34 27)" />
          </g>
          <ellipse cx="41" cy="40" stroke={color || "#fff"} rx="40" ry="39" />
        </g>
      </g>
    </svg>
  );
};

export const PauseButton = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="82" height="80" viewBox="0 0 82 80">
      <g fill="none" fillRule="evenodd">
        <g>
          <g fill="#fff">
            <path d="M0 0H8V22H0zM13 0H21V22H13z" transform="translate(31 29)" />
          </g>
          <ellipse cx="41" cy="40" stroke="#fff" rx="40" ry="39" />
        </g>
      </g>
    </svg>
  );
};
