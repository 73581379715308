import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./styles/index.scss";

import Navbar from "./components/navbar";
import Home from "./components/home";
import Episode from "./components/episode";
import Shows from "./components/shows";
import Show from "./components/show";
import About from "./components/about";
import Schedule from "./components/schedule";
import FourOFour from "./components/404";
import Discover from "./components/discover";
import Search from "./components/search";
import Article from "./components/article";
import Privacy from "./components/privacy";
import SummerCamp from "./components/summer-camp";
import { AppContext } from "./contexts/AppContext";

function App() {
  return (
    <AppContext>
      <div id="app">
        <Router>
          <div id="contentWrapper">
            <Navbar />
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/episode/:id" component={Episode} />
              <Route path="/shows/:id" component={Shows} />
              <Route path="/show/:id" component={Show} />
              <Route path={["/discover", "/archives"]} component={Discover} />
              <Route path="/about" component={About} />
              <Route path="/schedule" component={Schedule} />
              <Route path="/search" component={Search} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/article/:handle" component={Article} />
              <Route path="/summer-camp-2022" component={SummerCamp} />
              <Route component={FourOFour} />
            </Switch>
          </div>
        </Router>
      </div>
    </AppContext>
  );
}

export default App;
