import React from "react";
import { Remarkable } from "remarkable";
var md = new Remarkable({
  html: true
});

const About = ({
    description,
    poster: {
        url,
        caption,
      	alternativeText
    }
}) => {

  return (
    <div id="about">
      <div className="left" style={{backgroundColor: 'transparent'}}>
        <img key={url} alt={alternativeText || caption} src={url} />
      </div>
      <div className="right">
        <div dangerouslySetInnerHTML={{ __html: md.render(description) }} />
      </div>
    </div>
  );
};

export default About;
