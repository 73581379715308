import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import URI from "urijs";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Carousel({ entries }) {
  const slickSettings = {
    dots: false,
    fade: false,
    infinite: true,
    speed: 300,
    autoplay: true,
    adaptiveHeight: false,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    lazyload: "ondemand",
    slidesToShow: 1,
    arrows: false,
    draggable: false,
    slidesToScroll: 1,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1080,
        appendDots: null,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          draggable: true
        }
      }
    ]
  };

  return (
    <div id="carousel">
      <Slider {...slickSettings}>
        {entries
          .filter(e => e.episode).map(e => e.episode)
          .filter(e => e.image && e.image.formats && e.image.formats.large)
          .map((c, i) => (
            <div key={c.slug} className="carouselEntry">
              <div className="carouselContent">
                <Link to={new URI("/episode/" + c.slug).pathname()}>
                  <img src={c.image.formats.large.url} alt={`Carousel entry ${i}`} />
                </Link>
                <div className="infos">
                  <p className="title">
                    <Link to={new URI("/episode/" + c.slug).pathname()}>{c.title}</Link>
                  </p>
                  <p className="headline">
                    <Link to={new URI("/episode/" + c.slug).pathname()}>{c.description}</Link>
                  </p>
                  <div className="tags">
                    {(c.styles || [])
                      .filter((e, i) => i < 3)
                      .map(t => (
                        <div className="tag" key={t.name}>
                          <span>{t.name}</span>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </Slider>
    </div>
  );
}
