import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDebounce } from "use-debounce";
import { GrClose } from "react-icons/gr";

const offFocusPlaceholder = "Type your own shit right here...";
const onFocusPlaceholder = "Artist, Show, Genre…";

const SearchBar = props => {
  const [placeholder, setPlaceholder] = useState(offFocusPlaceholder);
  const [text, setText] = useState(props.query || "");
  const [value] = useDebounce(text, 500);
  const input = useRef();

  const { onChange } = props;

  useEffect(() => {
    input.current.focus();
  }, []);

  useEffect(() => {
    if (onChange) onChange(value);
  }, [value, onChange]);

  const onFocus = useCallback(() => {
    setPlaceholder(onFocusPlaceholder);
  }, [setPlaceholder]);

  const onFocusOut = useCallback(() => {
    setPlaceholder(offFocusPlaceholder);
  }, [setPlaceholder]);

  const onCancel = useCallback(() => {
    setText("");
    if (onChange) onChange("");
  }, [setText, onChange]);

  const onInputChange = e => {
    e.persist();
    setText(e.target.value);
  };

  return (
    <div {...props}>
      <div className="searchbar">
        <input
          ref={input}
          type="text"
          autoFocus
          placeholder={placeholder}
          onFocus={onFocus}
          onBlur={onFocusOut}
          value={text}
          onChange={onInputChange}
        />
        <button type="reset" id="cancel" onClick={onCancel}>
          <GrClose />
        </button>
      </div>
    </div>
  );
}

export default SearchBar;