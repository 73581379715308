import React from "react";
import moment from "moment";

export const Venue = ({
  venue: {
    name,
    description,
    address,
    picture: {
      url,
      caption,
      alternativeText,
    },
    date,
    startAt,
    endAt,
  },
  events
}) => {
  const artists = events ? events.filter(e => e.venue === name)
    .map(e => e.artists)
    .join(', ') : undefined
    
  return (
  <div className="venue">
    <div className="content">
      <div className="left">
        <img alt={alternativeText || caption} src={url} />
      </div>
      <div className="right">
        <div className="details">
          <div className="date">
            <p>
              {
                moment(date).format("ddd DD/MM/YYYY")
              } • {
                moment(startAt, "HH:mm:ss.SSS").format("hA")
              } - {
                moment(endAt, "HH:mm:ss.SSS").format("hA")
              }
            </p>
          </div>
          <h1>{name}</h1>
          <h2>{address}</h2>
          <h2>with {artists}</h2>
        </div>
        <div className="description">
          <p>{description}</p>
        </div>
      </div>
    </div>
  </div>
  );
};

export default Venue;