import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { datadogRum } from '@datadog/browser-rum';

ReactDOM.render(<App />, document.getElementById('root'));

datadogRum.init({
    applicationId: '6f01931a-7ac3-45f7-8232-b162c2cb21dd',
    clientToken: 'pubc712921498d0062bbb9989c6cc9d6f17',
    site: 'datadoghq.eu',
    service: 'lyl.live',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
