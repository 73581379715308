import React, { useEffect, useState, useRef } from "react";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import Player from "../components/global/player";

export const Context = React.createContext({ player: null });

export const AppContext = props => {
  const { children } = props;
  const playerRef = useRef(null);

  const [client] = useState(new ApolloClient({ 
    uri: process.env.REACT_APP_API_ENDPOINT, 
    cache: new InMemoryCache() 
  }));

  useEffect(() => {
    const getConfig = async () => {};

    getConfig();
    return () => {};
  }, []);

  return (
    <Context.Provider value={{ playerRef }}>
      <ApolloProvider client={client}>
        <Player ref={playerRef} />
        {children}
      </ApolloProvider>
    </Context.Provider>
  );
};

export const { Consumer } = Context;
