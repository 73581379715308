import { useState } from "react";
import moment from "moment-timezone";

const useTimezone = () => {
  const [utcOffset] = useState(moment().utcOffset());
  const [radioUtcOffset] = useState(moment().tz("Europe/Paris").utcOffset());
  const [myTimezone] = useState(moment.tz.guess());

  const getLocalTimeForEpisode = episode => {
    const startHour = moment(episode.startTime, "HH:mm:ss").hour();
    const endHour = moment(episode.endTime, "HH:mm:ss").hour();
    const episodeStart = moment(episode.date).utcOffset(radioUtcOffset).hour(startHour);
    const episodeEnd = moment(episode.date).utcOffset(radioUtcOffset).hour(endHour);

    return { start: episodeStart.tz(myTimezone), end: episodeEnd.tz(myTimezone) };
  };

  const getLocalTimeForSchedule = ({ day: dayString, week, year, hour }) => {
    const time = moment()
      .utcOffset(radioUtcOffset)
      .week(week)
      .year(year)
      .day(dayString)
      .hour(parseInt(hour))
      .seconds(0)
      .minutes(0);
    return time.tz(myTimezone);
  };

  return { radioUtcOffset, utcOffset, myTimezone, getLocalTimeForEpisode, getLocalTimeForSchedule };
};

export default useTimezone;
