import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Meta from "./global/meta";
import Four0Four from "./404";
import Loader from "./global/loader";
import { BsArrowRight } from "react-icons/bs";
import { GET_ARTICLE } from "../@services/graphql";
import { Play } from "./global/buttons";
import moment from "moment-timezone";
import { Remarkable } from "remarkable";
const md = new Remarkable();

const Article = props => {
  const { data, loading } = useQuery(GET_ARTICLE, {
    variables: { slug: props.match.params.handle }
  });

  const article = data && data.post;

  if (loading) return <Loader />;
  else if (article === null) return <Four0Four />;

  const getContentByType = data => {
    switch (data.__typename) {
      case "ComponentBlogParagraph":
        return (
          <div key={data.id} className="paragraph">
            <p dangerouslySetInnerHTML={{ __html: md.render(data.text) }} />
          </div>
        );
      case "ComponentBlogInterview":
        return (
          <div key={data.id} className="interview">
            <p className="question">{data.question}</p>
            <p dangerouslySetInnerHTML={{ __html: md.render(data.response) }} />
          </div>
        );
      case "ComponentBlogTracklist":
        return (
          <div key={data.id} className="tracklist">
            {data.tracks.map(t => (
              <div key={t.title} className="track">
                <hr />
                <p>
                  {t.artist} - {t.title}
                </p>
                {t.episode && t.episode.slug ? (
                  <Link to={`/episode/${t.episode.slug}`}>
                    <span>
                      <BsArrowRight />
                    </span>
                    <span>Listen back to the episode</span>
                  </Link>
                ) : null}
              </div>
            ))}
          </div>
        );
      case "ComponentBlogWidget":
        return <div key={data.id} className="widget" dangerouslySetInnerHTML={{ __html: data.html }} />;
      case "ComponentBlogImage":
        return data.file ? (
          <div key={data.id} className="image">
            <img src={data.file.formats.large.url} alt={data.file.alternativeText} />
          </div>
        ) : null;
      case "ComponentBlogEpisode":
        return (
          <div key={data.id} className="episode">
            <div className="playerAndTitle">
              <div>
                {data.episode.mixcloud ? (
                  <Play url={data.episode.mixcloud} isPlaying={false} />
                ) : (
                  <p>Replay not available</p>
                )}
              </div>
              <div className="right">
                <div className="date">
                  <p>
                    {moment(data.episode.startAt).format("ddd DD/MM/YYYY • hA")} -{" "}
                    {moment(data.episode.startAt)
                      .add(parseInt(data.episode.duration), "hours")
                      .format("hA")}
                  </p>
                </div>
                <Link to={`/episode/${data.episode.slug}`}>
                  <h1> {data.episode.title}</h1>
                </Link>
                <h2>with {data.episode.artists}</h2>
              </div>
            </div>
          </div>
        );
      case "ComponentBlogQuote":
        return (
          <div key={data.id} className="quote">
            <p>"{data.text}"</p>
          </div>
        );
      default:
    }
  };

  return (
    <div id="article">
      <Meta description={article.title} title={article.title} />
      <div className="content">
        <div className="title">
          <h1>{article.title}</h1>
          <div className="authors">{article.with ? <h2>With {article.with}</h2> : null}</div>
        </div>

        {article.artwork && article.artwork.formats.extraLarge ? (
          <div className="mainArtwork">
            <img src={article.artwork.formats.extraLarge.url} alt={article.artwork.alternativeText || "main artwork"} />
          </div>
        ) : null}

        {article.content && article.content.map(c => getContentByType(c))}

        <div className="by">
          {article.authors ? (
            <h2>
              Post by {article.authors} on {moment(article.published_at).format("ll")}
            </h2>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Article;
