import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import Meta from "./global/meta";
import Loader from "./global/loader";
import { GET_DISCOVER } from "../@services/graphql";
import { TileEpisode } from "./global/tile";

const Discover = () => {
  const [episodes, setEpisodes] = useState([]);
  const [skip, setSkip] = useState(0);

  const perPage = 40;

  const [get, { loading, data }] = useLazyQuery(GET_DISCOVER, {
    errorPolicy: 'all',
    variables: {
      limit: perPage,
      skip,
      sort: "startAt:desc"
    }
  });

  useEffect(() => {
    if (get) get();
  }, [get]);

  useEffect(() => {
    if (data && data.episodes) setEpisodes(e => e.concat(data.episodes));
  }, [data]);

  const handleLoadMore = () => {
    setSkip(skip + perPage);
    get();
  };

  if (episodes === undefined) return <Loader />;

  return (
    <div id="discover">
      <Meta description="Discover our latest episodes" title="Discover" />
      <div className="header">
        <h1>Our previous broadcasts</h1>
      </div>
      <section className="episodes">
        <div className="mosaique">
          {episodes.filter(e => e).map(e => (
            <TileEpisode key={e.slug} entry={e} />
          ))}
        </div>
      </section>
      <div className="loadMore">
        {loading ? (
          <Loader />
        ) : (
          <button type="button" onClick={handleLoadMore}>
            Load More
          </button>
        )}
      </div>
    </div>
  );
};

export default Discover;
