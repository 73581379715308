import React from "react";
import { useQuery } from "@apollo/client";
import { Link } from "react-router-dom";
import Meta from "./global/meta";
import Four0Four from "./404";
import useSlug from "../hooks/useSlug";
import Loader from "./global/loader";
import moment from "moment-timezone";
import { GET_EPISODE, GET_EPISODES_BY_SHOW } from "../@services/graphql";
import { TileEpisode } from "./global/tile";
import { Play } from "./global/buttons";
import Links from "./global/links";
import { Remarkable } from "remarkable";
const md = new Remarkable({
  breaks: true,
});

const Episode = props => {
  const { data } = useQuery(GET_EPISODE, { 
    variables: { slug: useSlug() }
  });

  const episode = data && data.episode ? data.episode : data;

  if (episode === null) return <Four0Four />;
  else if (episode === undefined) return <Loader />;

  const image =
    episode.image && episode.image.formats && episode.image.formats.large
      ? episode.image.formats.large
      : { url: process.env.REACT_APP_DEFAULT_IMAGE };

  const metaImage = (episode.image && episode.image.formats && episode.image.formats.small) || {
    url: process.env.REACT_APP_DEFAULT_IMAGE
  };

  const tracklist = () => {
    if (episode.tracks) {
      const tracks = episode.tracks
      return <div className="tracklist" dangerouslySetInnerHTML={{ __html: md.render(tracks) }} />
    }

    return (episode.tracklist || []).map(t => (
      <div className="tracklist">
        <ul>
          <li className="track" key={t.title}>
            <span>{t.title}</span>
          </li>
        </ul>
      </div>
    ))
  }

  return (
    <div id="episode">
      <Meta description={episode.description} title={episode.title} image={metaImage.url} />
      <div className="content">
        <div className="left">
          <img alt={image.alternativeText || image.caption} src={image.url} />
        </div>
        <div className="right">
          <div className="header">
            <div className="playerAndTitle">
              <div>
                {episode.mixcloud ? <Play url={episode.mixcloud} isPlaying={false} /> : <p>Replay not available</p>}
              </div>
              <div className="right">
                <div className="date">
                  <p>
                    {moment(episode.startAt).format("ddd DD/MM/YYYY • hA")} -{" "}
                    {moment(episode.startAt).add(parseInt(episode.duration), "hours").format("hA")}
                  </p>
                </div>
                <h1>{episode.title}</h1>
                <h2>with {episode.artists}</h2>
              </div>
            </div>
          </div>
          <div className="description">
            <p>{episode.description}</p>
            <div className="tags">
              {(episode.styles || []).map(t => (
                <div className="tag" key={t.name}>
                  <span>{t.name}</span>
                </div>
              ))}
            </div>
          </div>
          {tracklist()}
          {episode.show && episode.show.links ? (
            <div className="linksAndLink">
              <Links links={episode.show.links} />
              <Link to={"/show/" + episode.show.slug}>More episodes</Link>
            </div>
          ) : null}
        </div>
      </div>
      {episode.show && episode.show.id ? (
        <Suggestions showId={episode.show && episode.show.id} currentEpisode={episode.id} />
      ) : null}
    </div>
  );
}

const Suggestions = ({ showId, currentEpisode }) => {
  const { data } = useQuery(GET_EPISODES_BY_SHOW, {
    errorPolicy: 'all',
    variables: { show: showId, not: currentEpisode, limit: 8 }
  });

  const episodes = data && data.episodesByShow ? data.episodesByShow : data;

  if (episodes === null) return null;
  else if (episodes === undefined) return <Loader />;

  return (
    <div className="suggestions">
      <h2>More episodes</h2>
      <section id="latest">
        {episodes.length ? (
          <div className="mosaique">
            {episodes.map(e => (
              <TileEpisode key={e.slug} entry={e} />
            ))}
          </div>
        ) : (
          <p>There doesn't seem to be anymore available episode...</p>
        )}
      </section>
    </div>
  );
};

export default Episode;