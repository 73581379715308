import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import Meta from "./global/meta";
import moment from "moment-timezone";
import Loader from "./global/loader";
import Schedule from "./global/schedule";
import { GET_SCHEDULE_PAGE } from "../@services/graphql";
import useTimezone from "../hooks/useTimezone";

const Calendar = props => {
  const { myTimezone } = useTimezone();
  const [before] = useState(moment().add(1, "week").endOf("day").toISOString());
  const [after] = useState(moment().startOf("day").toISOString());
  const { data, loading } = useQuery(GET_SCHEDULE_PAGE, {
    errorPolicy: 'all',
    variables: { after, before }
  });

  const schedule = data ? data.schedule : null;

  if (loading || !data) return <Loader />;

  return (
    <div id="schedulePage">
      <Meta title="Schedule" />
      <div className="header">
        <h1>Schedule</h1>
        <p>Your timezone: {myTimezone}</p>
      </div>
      <Schedule schedule={schedule} />
    </div>
  );
};

export default Calendar;
