import React from "react";
import { useQuery } from "@apollo/client";
import { Remarkable } from "remarkable";
import { linkify } from 'remarkable/linkify';
import Four0Four from "./404";
import Meta from "./global/meta";
import Loader from "./global/loader";
import { GET_PRIVACY_POLICY } from "../@services/graphql";
const md = new Remarkable().use(linkify);

const Privacy = () => {
  const { data, loading } = useQuery(GET_PRIVACY_POLICY);
  console.log(data)

  const privacy = data && data.privacyPolicy;

  if (loading) return <Loader />;
  else if (!privacy) return <Four0Four />;

  return (
    <div id="privacy">
      <Meta description="Privacy Policy" title="Privacy Policy" />
      <div className="content">
        <div className="title">
          <h1>Privacy Policy</h1>
        </div>
        <div className="paragraph" dangerouslySetInnerHTML={{ __html: md.render(privacy.policy) }} />
      </div>
    </div>
  );
};

export default Privacy;