import React, { useState, useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import URI from "urijs";
import moment from "moment";
import Meta from "../global/meta";
import Four0Four from "../404";
import Loader from "../global/loader";
import { GET_SUMMER_CAMP } from "../../@services/graphql";
import About from "./about";
import Events from "./events";
import Event from "./event";
import Venues from "./venues";
import Venue from "./venue";

const SECTIONS = {
  HOME: "Accueil", 
  ARTISTS: "Artistes",
  VENUES: "Lieux", 
  TICKETS: "Billetterie",
}

const SummerCamp = props => {
  const { data } = useQuery(GET_SUMMER_CAMP);
  const location = useLocation();
  const [page, setPage] = useState(SECTIONS.HOME);
  const [id, setId] = useState(undefined);

  useEffect(() => {
    const uri = new URI(location.pathname + location.search);
    const segment = uri.segment(1);
    const p = uri.search(true).p;
    if (p) { 
      setPage(p);
      setId(undefined);
    } else if (segment === "venue") {
      setPage(SECTIONS.VENUES);
      setId(uri.segment(2));
    } else if (segment === "event") {
      setPage(SECTIONS.ARTISTS);
      setId(uri.segment(2));
    }
  }, [location])

  const selectPage = useCallback(p => {
    if (p === SECTIONS.TICKETS) {
      window.open("https://www.helloasso.com/associations/lyl-radio/evenements/camps-d-ete-lyl-radio");
      return;
    }
    
    const uri = new URI(location.pathname + location.search);
    uri.segment(1, "");
    uri.segment(1, "");
    uri.setSearch({ p });
    props.history.push({ pathname: uri.pathname(), search: uri.search() });
    setPage(p)
    setId(undefined)
  }, [props, location, setPage, setId])

  const selectVenue = useCallback(v => {
    const uri = new URI(location.pathname + location.search);
    uri.segment(1, "venue");
    uri.segmentCoded(2, v.id);
    props.history.push({ pathname: uri.pathname() });
    setId(v.id);
  }, [props, location, setId])

  const selectEvent = useCallback(e => {
    const uri = new URI(location.pathname + location.search);
    uri.segment(1, "event");
    uri.segmentCoded(2, e.id);
    props.history.push({ pathname: uri.pathname() });
    setId(e.id);
  }, [props, location, setId])

  const festival = data && data.summerCamp2022 ? data.summerCamp2022 : data;

  if (festival === null) return <Four0Four />;
  else if (festival === undefined) return <Loader />;

  const pages = Object.values(SECTIONS);

  const Content = props => {
    const venue = id && festival.venues.find(v => v.id === id);
    if (venue) {
      return <Venue venue={venue} events={festival.events} />
    }
    const event = id && festival.events.find(e => e.id === id);
    if (event) {
      return <Event event={event} />
    }
    if (page === SECTIONS.HOME) {
      return <About description={festival.description} poster={festival.poster} />
    }
    if (page === SECTIONS.ARTISTS) {
      return <Events events={festival.events} onSelect={selectEvent} />
    }
    if (page === SECTIONS.VENUES) {
      return <Venues venues={festival.venues} events={festival.events} onSelect={selectVenue} />
    }
    return <div />
  }

  const dates = `${moment(festival.startDate).format("D")} - ${moment(festival.endDate).format("D MMMM YYYY")}`

  return (
    <div id="festival">
      <Meta description={"Camp d'Été 2022 LYL"} title="Camp d'Été 2022 LYL" />
      <div className="header">
        <h1>{dates}</h1>
        <h1>{festival.title}</h1>
        <h1>{festival.city}</h1>
      </div>
      <section className="filters">
        <ul className="pages">
          {pages.map(p => (
            <li key={p} className="page">
              <button
                onClick={e => selectPage(p)}
                className={p === page ? "active" : ""}
                type="button">
                {p}
              </button>
            </li>
          ))}
        </ul>
      </section>
      <Content />
    </div>
  );
};

export default SummerCamp;