import React, { useState } from "react";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";
import { Play } from "./buttons";

export const TileEpisode = ({ entry: { mixcloud, artists, slug, startAt, date, styles, title, image } }) => {
  const [isHover, setIsHover] = useState(false);
  const imageUri =
    image && image.formats && image.formats.small ? image.formats.small.url : process.env.REACT_APP_DEFAULT_IMAGE;
  const path = `/episode/${slug}`;

  return (
    <div
      className={`tile ${isHover ? "hover" : ""}`}
      onMouseEnter={e => setIsHover(true)}
      onMouseLeave={e => setIsHover(false)}>
      <div className="tileImage">
        {mixcloud ? (
          <>
            <LazyLoadImage alt={title} src={imageUri} />
            <Play color="#fff" url={mixcloud} isPlaying={false} />
          </>
        ) : (
          <>
            {imageUri ? (
              <Link to={path}>
                <LazyLoadImage alt={title} src={imageUri} />
              </Link>
            ) : (
              <p>NO IMAGE</p>
            )}
          </>
        )}
      </div>
      <div className={`tileInfos`}>
        <p className="date">{moment(startAt).format("DD.MM.YY")}</p>
        <Link to={path}>
          <p className="title">{title}</p>
          <p className="artists">{artists}</p>
        </Link>
        <div className="tags">
          {styles && styles.length
            ? styles
                .filter((s, i) => i < 4)
                .map(t => (
                  <span className="tag" key={t.name}>
                    {t.name}
                  </span>
                ))
            : null}
        </div>
      </div>
    </div>
  );
};

export const TileShow = ({ entry: { artists, nextBroadcast, slug, recursion, date, styles, title, image } }) => {
  const [isHover, setIsHover] = useState(false);
  const imageUri =
    image && image.formats && image.formats.small ? image.formats.small.url : process.env.REACT_APP_DEFAULT_IMAGE;
  const path = `/show/${slug}`;

  return (
    <div
      className={`tile ${isHover ? "hover" : ""}`}
      onMouseEnter={e => setIsHover(true)}
      onMouseLeave={e => setIsHover(false)}>
      <div className="tileImage">
        {imageUri ? (
          <Link to={`/show/${slug}`}>
            <LazyLoadImage alt={title} src={imageUri} />
          </Link>
        ) : (
          <p>NO IMAGE</p>
        )}
      </div>
      <div className={`tileInfos`}>
        <p className="date">{recursion}</p>
        <p className="title">
          <Link to={path}>{title}</Link>
        </p>
        <p className="artists">
          <Link to={path}>With {artists}</Link>
        </p>
        {nextBroadcast ? (
          <div className="nextBroadcast">
            <p>Next on {moment(nextBroadcast).format("MMM Do")}</p>
            <p>{moment(nextBroadcast).format("HA")}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export const TilePost = ({ entry: { slug, title, artwork: image } }) => {
  const [isHover, setIsHover] = useState(false);
  const imageUri =
    image && image.formats && image.formats.small ? image.formats.small.url : process.env.REACT_APP_DEFAULT_IMAGE;
  const path = `/article/${slug}`;

  return (
    <div
      className={`tile post ${isHover ? "hover" : ""}`}
      onMouseEnter={e => setIsHover(true)}
      onMouseLeave={e => setIsHover(false)}>
      <div className="tileImage">
        {imageUri ? (
          <Link to={path}>
            <LazyLoadImage alt={title} src={imageUri} />
          </Link>
        ) : (
          <p>NO IMAGE</p>
        )}
      </div>
      <div className={`tileInfos`}>
        <p className="titleHeader">Best of the Rest</p>
        <p className="title">
          <Link to={path}>{title}</Link>
        </p>
      </div>
    </div>
  );
};
