import React from "react";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

const calendar = (schedule) => {
  var days = {};

  for (const broadcast of schedule) {
    const startAt = moment(broadcast.startAt);
    const date = startAt.format("dddd MMMM Do");
    var day = days[date] || {};
    const hour = startAt.format("hhA");
    day[hour] = broadcast;
    days[date] = day;
  }

  return days;
}

const Schedule = ({ schedule }) => {
  let days = calendar(schedule);

  const broadcast = b => {
    if (b.show) {
      return <Link to={"/show/" + b.show.slug}>{b.title}</Link>;
    }
    if (b.episode) {
      return <Link to={"/episode/" + b.episode.slug}>{b.title}</Link>;
    }
    return <span>{b.title} </span>;
  };

  // TODO: backend
  const artist = b => {
    if (b.show) return b.show.artists;
    if (b.episode) return b.episode.artists;
  };

  const dates = Object.keys(days);

  return (
    <div id="schedule">
      <div id="days">
        {dates.map((date, i) => (
          <div key={i} className="day">
            <div className="header">
              <p>{date}</p>
            </div>
            {Object.keys(days[date]).map((hour, i) => (
                <div key={i} className="slot">
                  <p>{hour}</p>
                  <p>
                    {/* <span id="live"> {e.isNow ? "NOW" : null} </span> */}
                    {broadcast(days[date][hour])}
                  </p>
                  <p>{artist(days[date][hour])}</p>
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Schedule;
