import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_ONAIR } from "../@services/graphql";

export function useOnAir() {
  const [load, { called, data }] = useLazyQuery(GET_ONAIR, {
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    // Update every minutes
    if (!called) return load()
    const interval = setInterval(load, 60000)
    return () => clearInterval(interval)
  }, [called, data, load]);

  return data ? data.onair : undefined;
}