import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Meta = props => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => (window.prerenderReady = true), 50);
  }, [location.pathname, location.search]);

  const indexValue = props.noIndex ? "noindex" : "index";

  let indexMetaSelector = document.querySelector('meta[name="robots"]');
  if (!indexMetaSelector) {
    indexMetaSelector = document.createElement("meta");
    indexMetaSelector.setAttribute("name", "robots");
    indexMetaSelector = document.getElementsByTagName("head")[0].appendChild(indexMetaSelector);
  }
  indexMetaSelector.setAttribute("content", indexValue);

  const canonical = location.pathname !== undefined ? "https://lyl.live" + location.pathname : null;
  const title = props.title || `LYL Radio | We're the rest.`;
  const defaultImage = process.env.REACT_APP_DEFAULT_IMAGE;
  return (
    <Helmet>
      <title>{"LYL Radio | " + title}</title>
      {canonical ? <link rel="canonical" href={canonical} /> : null}
      {canonical ? <meta property="og:url" content={canonical} /> : null}
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      {props.fourOFour ? <meta name="prerender-status-code" content="404" /> : null}
      {props.description || props.title ? (
        <meta property="og:description" content={props.description || props.title} />
      ) : null}
      <meta property="og:image" content={props.image || defaultImage} />
    </Helmet>
  );
}

export default Meta;
