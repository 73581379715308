import React from "react";
import Meta from "./global/meta";

const Four0Four = () => {
  return (
    <div id="fourOFour">
      <Meta description="404" fourOFour={true} noIndex={true} title="Not found" />
      <img alt="not found" src="/images/try-again.gif" />
    </div>
  );
};

export default Four0Four;