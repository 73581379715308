import React, { useContext } from "react";
import { Context } from "../../contexts/AppContext";
import { PlayButton, PauseButton } from "./icons";

export const Play = ({ children, url, isPlaying, color = "#000" }) => {
  const { playerRef } = useContext(Context);

  const handleClick = () => {
    playerRef.current.loadMixcloud(url);
  };

  return (
    <button className="playButton" onClick={handleClick}>
      {isPlaying ? <PauseButton color={color} /> : <PlayButton color={color} />}
    </button>
  );
};
