import React from "react";

const Loader = props => {
  return (
    <div className="loader" style={props.style}>
      <img alt="loader" src="/images/its-comin.gif"></img>
    </div>
  );
}

export default Loader;
