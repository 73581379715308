import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import Meta from "./global/meta";
import moment from "moment-timezone";
import Loader from "./global/loader";
import { TileEpisode, TilePost } from "./global/tile";
import Carousel from "./global/carousel";
import Schedule from "./global/schedule";
import { GET_HOME_PAGE, GET_POSTS } from "../@services/graphql";
import useTimezone from "../hooks/useTimezone";

const Home = props => {
  const { myTimezone } = useTimezone();
  const [after] = useState(moment().startOf("day"));
  const [before] = useState(
    moment()
      .startOf("day")
      .add(1, "days")
      .endOf("day")
  );

  const { data, loading } = useQuery(GET_HOME_PAGE, {
    errorPolicy: "all",
    variables: {
      after: after.format(),
      before: before.format(),
      limit: 8
    }
  });

  const { data: blog, loading: loadingBlog } = useQuery(GET_POSTS);

  const results = data ? data : undefined;

  const headlines = results ? results.headline : undefined;
  const choices = results ? results.editorschoice : undefined;
  const latest = results ? results.latest : undefined;
  const schedule = results ? results.schedule : undefined;
  const posts = blog ? blog.posts : undefined;

  if (loading || !results) return <Loader />;

  return (
    <div id="home">
      <Meta />
      <section>{headlines.banners ? <Carousel entries={headlines.banners} /> : <Loader />}</section>
      {choices ? (
        <section id="editorschoice">
          <h2>Editor's choice</h2>
          <div className="mosaique">
            {choices.map(e => (
              <TileEpisode key={e.slug} entry={e} />
            ))}
          </div>
        </section>
      ) : null}
      {latest ? (
        <section id="latest">
          <h2>Latest</h2>
          <div className="mosaique">
            {latest
              .filter(l => !!l)
              .map(e => (
                <TileEpisode key={e.slug} entry={e} />
              ))}
          </div>
        </section>
      ) : null}
      {schedule ? (
        <section className="schedule">
          <div className="header">
            <h1>Schedule</h1>
            <p>Your timezone: {myTimezone}</p>
          </div>
          <Schedule schedule={schedule} />
        </section>
      ) : null}
      {loadingBlog ? (
        <Loader />
      ) : (
        <>
          {posts? (
            <section className="posts">
            <div className="header">
              <h1>Articles</h1>
              {0 ? <Link to="/">View more</Link> : null}
            </div>
            <div className="mosaique">
              {posts
                .filter(l => !!l)
                .map(p => (
                  <TilePost key={p.slug} entry={p} />
                ))}
            </div>
          </section>
          ) : null}
        </>
      )}
    </div>
  );
};

export default Home;
