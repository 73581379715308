import React, { useState } from "react";
import moment from "moment";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Venues = (props) => {
  return (
    <div id="festival">
      <section className="entries">
          <div className="mosaique">
            {props.venues.map(v => {
              const artists = props.events
                .filter(e => e.venue === v.name)
                .map(e => e.artists)
                .join(', ')

              return (
                <TileVenue
                  key={v.name}
                  venue={v}
                  artists={artists}
                  onSelect={ () => props.onSelect(v) }
                />
              )
            })}
          </div>
        </section>
    </div>
  )
};

export const TileVenue = ({
  venue: {
    name,
    picture: {
      url,
      caption,
      alternativeText,
    },
    date,
    startAt,
    endAt
  },
  artists,
  onSelect
}) => {
  const [isHover, setIsHover] = useState(false);
  const image = url || caption || alternativeText || process.env.REACT_APP_DEFAULT_IMAGE;

  return (
    <div
      className={`tile ${isHover ? "hover" : ""}`}
      onMouseEnter={e => setIsHover(true)}
      onMouseLeave={e => setIsHover(false)}
      onClick={onSelect}>
        <div className="tileImage">
          {image ?
            <LazyLoadImage alt={name} src={image} /> :
            <p>NO IMAGE</p>
          }
        </div>
        <div className={`tileInfos`}>
          <p className="title">
            {name}
          </p>
          {artists ?
            <p className="artists">
              With {artists}
            </p>: undefined
          }
          <div className="nextBroadcast">
            <p>On {moment(date).format("MMM Do")}</p>
            <p>{
              moment(startAt, "HH:mm:ss.SSS").format("HA")
            } - {
              moment(endAt, "HH:mm:ss.SSS").format("HA")
            }</p>
          </div>
        </div>
    </div>
  );
};

export default Venues;