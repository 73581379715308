import { gql } from "@apollo/client";

const imageFields = `id
alternativeText
url
formats`;

const episode = `
  id
  title
  slug
  artists
  startAt
  duration
  mixcloud
  show {
    id
    slug
    links {
      type
      url
    }
  }
  description
  tracks
  tracklist {
    title
  }
  styles(limit: 4) {
    name
  }
  image {
    ${imageFields}
  }
`;

const show = `
id
slug
title
recursion
styles(limit: 3) {
  name
}
nextBroadcast
links {
  type
  url
}
description
artists
image {
  ${imageFields}
}`;

const blogContent = `
  content {
    __typename
    ... on ComponentBlogParagraph {
      id
      text
    }
    ... on ComponentBlogInterview {
      id
      question
      response
    }
    ... on ComponentBlogTracklist{
      id
      tracks {
        artist
        title
        episode {
          slug
        }
      }
    }
    ... on ComponentBlogWidget {
      id
      html
    }
    ... on ComponentBlogImage {
      id
      file {
        ${imageFields}
      }
    }
    ... on ComponentBlogEpisode {
      id
      episode {
        ${episode}
      }
    }
    ... on ComponentBlogQuote {
      id
      text
    }
  }
`;

export const GET_EPISODES = gql`
  query getEpisodes($limit: Int!, $show: String) {
    episodes(limit: $limit, show: $show) {
      ${episode}
    }
  }
`;

export const GET_ARTICLE = gql`
  query getArticle($slug: String!) {
    post: postBySlug(slug: $slug) {
      id
      title
      authors
      createdAt
      published_at
      with
      ${blogContent}
      artwork {
        ${imageFields}
      }
    }
  }
`;

export const GET_EPISODES_BY_SHOW = gql`
  query getEpisodes($limit: Int!, $skip: Int, $show: String!, $not: String) {
    episodesByShow(limit: $limit, start: $skip show: $show, not: $not, sort: "startAt:desc") {
      ${episode}
    }
  }
`;

export const GET_HOME_PAGE = gql`
  query getHomePage($limit: Int = 8, $after: DateTime!, $before: DateTime!) {
    headline {
      banners {
      ... on ComponentHeadlineEpisode {
          episode {
            ${episode}
          }
        }
      }
    }
    editorschoice: episodesByCategories(categories: ["Editor's Choice"], limit: $limit, sort: "startAt:desc") {
      ${episode}
    }
    latest: episodes(sort:"startAt:desc", limit: $limit) {
      ${episode}
    }
    schedule: calendar(from: $after, to: $before) {
      startAt
      title
      show {
        artists
        slug
      }
      episode {
        artists
        slug
      }
    }
  }
`;

export const GET_POSTS= gql`
  query getPosts($limit: Int = 8) {
    posts(sort: "published_at:desc", limit: $limit) {
      title
      slug
      artwork {
        ${imageFields}
      }
    }
  }
`;

export const GET_SCHEDULE_PAGE = gql`
  query getSchedule($after: DateTime!, $before: DateTime!) {
    schedule: calendar(from: $after, to: $before) {
      startAt
      title
      show {
        artists
        slug
      }
      episode {
        artists
        slug
      }
    }
  }
`;

export const GET_EPISODE = gql`
  query getEpisode($slug: String!) {
    episode: episodeBySlug(slug: $slug) {
      ${episode}
    }
  }
`;

export const GET_SHOWS_AND_FILTERS = gql`
  query getShows($limit: Int!, $skip: Int, $sort: String, $styles: [String!], $studios: [String!]) {
    showsByStyles(limit: $limit, sort: $sort, styles: $styles, start: $skip, studios: $studios) {
      ${show}
    }
    studios {
      name
      city
      id
    }
    styles(limit: 40, where:{ favourite: true }) {
      name
      id
    }
  }
`;

export const GET_DISCOVER = gql`
  query getEpisode($limit: Int!, $skip: Int, $sort: String) {
    episodes(limit: $limit, start: $skip, sort: $sort) {
      ${episode}
    }
  }
`;

export const GET_SEARCH = gql`
  query getSearch($start: Int = 0, $limit: Int = 32, $q: String) {
    shows(limit: $limit, where: { _q: $q }) {
      ${show}
    }
    episodes(
      start: $start,
      limit: $limit,
      sort: "startAt:desc",
      where: { _q: $q }
    ) {
      ${episode}
    }
  }
`;

export const GET_SHOW = gql`
  query getShow($slug: String!) {
    show: showBySlug(slug: $slug) {
      ${show}
    }
  }
`;

export const GET_STUDIOS = gql`
  query getstudios {
    studios {
      title
    }
  }
`;

export const GET_ABOUT = gql`
  query getAbout {
    about {
      description
      title
      contact {
        name
        email
        title
      }
      images {
        url
        alternativeText
        caption
      }
    }
  }
`;

export const GET_ONAIR = gql`
  query {
    onair {
      listenurl
      title
    }
  }
`;

export const GET_PRIVACY_POLICY = gql`
  query {
    privacyPolicy {
      policy
    }
  }
`;

export const GET_SUMMER_CAMP = gql`
query {
  summerCamp2022(publicationState: LIVE) {
    title
    startDate
    endDate
    description
    city
    poster {
      url
      caption
      alternativeText
    }
    events {
      id
      artists
      venue
      startAt
      description
      type
      tickets
      poster {
        url
        caption
      	alternativeText
      }
    }
    tickets {
      description
    }
    venues {
      id
      name
      description
      address
      picture {
        url
        caption
      	alternativeText
      }
      date
      startAt
      endAt
    }
  }
}
`;